<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="6" sm="4" md="3">
        <periods-filter></periods-filter>
      </v-col>
      <v-col cols="6" sm="4" md="3">
        <search-filter></search-filter>
      </v-col>
      <v-col cols="6" sm="4" md="3">
        <exclude-filter></exclude-filter>
      </v-col>
      <v-col cols="3">
        <v-btn
          @click="resetFilters"
          class="pt-7 pb-6 mt-0 my-1"
          outlined
          color="secondary"
        >
          Réinitialiser
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PeriodsFilter from "@/views/pages/admin/report-alert/filters/PeriodsFilter";
import { mdiCached } from "@mdi/js";
import SearchFilter from "@/views/pages/admin/report-alert/filters/SearchFilter.vue";
import ExcludeFilter from "@/views/pages/admin/report-alert/filters/ExcludeFilter.vue";

export default {
  name: "Filters",
  components: {
    SearchFilter,
    ExcludeFilter,
    PeriodsFilter,
    mdiCached,
  },
  data() {
    return {
      icons: {
        mdiCached,
      },
    };
  },
  methods: {
    resetFilters() {
      this.$store.dispatch("alerts/setFilterPeriods", []);
      this.$store.dispatch("alerts/setFilterSearch", "");
      this.$store.dispatch("alerts/setFilterExclude", "");
    },
  },
};
</script>
