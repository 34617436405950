<template>
  <v-autocomplete
    outlined
    v-model="selected"
    :items="periodsList"
    :menu-props="{ maxHeight: '400', zIndex: 13 }"
    label="Sélectionner les périodes"
    multiple
    small-chips
    deletable-chips
    item-text="label"
    item-value="id"
  ></v-autocomplete>
</template>

<script>
export default {
  data: () => ({
    periodsList: [
      { id: "d1", label: "1 jour" },
      { id: "d7", label: "7 jours" },
      { id: "d30", label: "30 jours" },
    ],
  }),
  computed: {
    selected: {
      get() {
        return this.$store.getters["alerts/getFilterPeriods"];
      },
      set(e) {
        this.$store.dispatch("alerts/setFilterPeriods", e);
      },
    },
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 233px !important;
  right: 0 !important;
}
</style>
