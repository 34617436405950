<template>
  <div>
    <h1 class="ma-3">{{ name }}</h1>
    <filters></filters>
    <v-data-table
      :headers="headers"
      :items="items"
      :multi-sort="true"
      :items-per-page="10"
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50, 100],
      }"
      class="elevation-1"
    >
      <template v-slot:item.period="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="text--primary text-xl font-weight-bold"
              v-on="on"
              v-bind="attrs"
            >
              <v-chip
                :color="
                  item.period === 'd1'
                    ? 'primary'
                    : item.period === 'd7'
                    ? 'warning'
                    : 'secondary'
                "
                dark
                small
              >
                {{
                  item.period === "d1"
                    ? "1 jour"
                    : item.period === "d7"
                    ? "7 jours"
                    : "30 jours"
                }}
              </v-chip>
            </span>
          </template>
          <span>{{ calcRange(item.period) }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "@axios";
import Filters from "@/views/pages/admin/report-alert/filters/Filters";

export default {
  name: "GeneratedAlertView",
  components: { Filters },
  created() {
    this.fetchData();
    this.markAsRead();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      headers: [
        { text: "Site", value: "site", sort: this.sortAlpha },
        { text: "Période", value: "period", sort: this.sortRange },
        { text: "Plateforme", value: "partner", sort: this.sortAlpha },
        { text: "Valeur entrée", value: "old", sort: this.sortNumeric },
        { text: "Valeur sortie", value: "new", sort: this.sortNumeric },
        { text: "Delta", value: "delta", sort: this.sortNumeric },
        { text: "Delta (%)", value: "ratio", sort: this.sortPercentNumeric },
      ],
      items: [],
      name: "",
    };
  },
  methods: {
    markAsRead() {
      axios.post(`/alert-system-generated-reports/${this.id}/mark-as-read/`);
    },
    calcRange(period) {
      const d1 = new Date();
      const d7 = new Date();
      const d30 = new Date();
      d7.setDate(d7.getDate() - 7);
      d30.setDate(d30.getDate() - 30);
      switch (period) {
        case "d1":
          return `${d1.toLocaleDateString()} - ${d1.toLocaleDateString()}`;
        case "d7":
          return `${d7.toLocaleDateString()} - ${d1.toLocaleDateString()}`;
        case "d30":
          return `${d30.toLocaleDateString()} - ${d1.toLocaleDateString()}`;
      }
    },
    sortRange: (a, b) => {
      const d1Index = ["d1", "d7", "d30"].indexOf(a);
      const d2Index = ["d1", "d7", "d30"].indexOf(b);
      return d1Index - d2Index;
    },
    sortAlpha: (a, b) => a.localeCompare(b),
    sortNumeric: (a, b) => Number(a) - Number(b),
    sortPercentNumeric: (a, b) =>
      Number(a.slice(0, -1)) - Number(b.slice(0, -1)),
    extractValues(content) {
      let valuesArray = content.split("=");
      return {
        old: valuesArray[0],
        new: valuesArray[1],
        delta: valuesArray[2],
        ratio: valuesArray[3],
      };
    },
    async fetchData() {
      const { data } = await axios.get(
        `/alert-system-generated-reports/${this.id}`
      );
      this.name = data.name;
      const content = JSON.parse(data.content);
      const newItems = [];

      for (const site in content) {
        for (const period in content[site]) {
          const metrics = ["ca", "sessions", "rpm"];

          metrics.forEach((metric) => {
            if (content[site][period][metric]) {
              const v = this.extractValues(content[site][period][metric]);
              const partner =
                metric === "sessions" ? "Sessions" : metric.toUpperCase();
              newItems.push({
                site,
                period,
                partner,
                ...v,
              });
            }
          });
          if (content[site][period].partners) {
            for (const partner in content[site][period].partners) {
              let v = {};
              v = this.extractValues(content[site][period].partners[partner]);
              newItems.push({
                site,
                period,
                partner: `${partner} (CA)`,
                old: v.old,
                new: v.new,
                delta: v.delta,
                ratio: v.ratio,
              });
            }
          }
          if (content[site][period].partnersRpm) {
            for (const partner in content[site][period].partnersRpm) {
              let v = {};
              v = this.extractValues(
                content[site][period].partnersRpm[partner]
              );
              newItems.push({
                site,
                period,
                partner: `${partner} (RPM)`,
                old: v.old,
                new: v.new,
                delta: v.delta,
                ratio: v.ratio,
              });
            }
          }
        }
      }
      this.items = newItems;
      const periods = this.$store.getters["alerts/getFilterPeriods"];
      if (periods.length > 0) {
        this.items = this.items.filter((item) => periods.includes(item.period));
      }
      const search = this.$store.getters["alerts/getFilterSearch"];
      const exclude = this.$store.getters["alerts/getFilterExclude"];
      if (search) {
        this.items = this.items.filter(
          (item) =>
            item.site.toLowerCase().includes(search.toLowerCase()) ||
            item.partner.toLowerCase().includes(search.toLowerCase())
        );
      }
      if (exclude) {
        this.items = this.items.filter(
          (item) =>
            !item.site.toLowerCase().includes(exclude.toLowerCase()) &&
            !item.partner.toLowerCase().includes(exclude.toLowerCase())
        );
      }
    },
  },
  watch: {
    id() {
      this.fetchData();
    },
    "$store.state.alerts.filterPeriods": {
      handler() {
        this.fetchData();
      },
    },
    "$store.state.alerts.filterSearch": {
      handler() {
        this.fetchData();
      },
    },
    "$store.state.alerts.filterExclude": {
      handler() {
        this.fetchData();
      },
    },
  },
};
</script>

<style scoped></style>
