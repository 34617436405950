var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"ma-3"},[_vm._v(_vm._s(_vm.name))]),_c('filters'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"multi-sort":true,"items-per-page":10,"footer-props":{
      'items-per-page-options': [5, 10, 25, 50, 100],
    }},scopedSlots:_vm._u([{key:"item.period",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary text-xl font-weight-bold"},'span',attrs,false),on),[_c('v-chip',{attrs:{"color":item.period === 'd1'
                  ? 'primary'
                  : item.period === 'd7'
                  ? 'warning'
                  : 'secondary',"dark":"","small":""}},[_vm._v(" "+_vm._s(item.period === "d1" ? "1 jour" : item.period === "d7" ? "7 jours" : "30 jours")+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.calcRange(item.period)))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }