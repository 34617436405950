<template>
  <v-text-field
    outlined
    v-model="search"
    :menu-props="{ maxHeight: '400', zIndex: 13 }"
    label="Recherche (par Plateforme ou par Site)"
  ></v-text-field>
</template>

<script>
export default {
  computed: {
    search: {
      get() {
        return this.$store.getters["alerts/getFilterSearch"];
      },
      set(e) {
        this.$store.dispatch("alerts/setFilterSearch", e);
      },
    },
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 233px !important;
  right: 0 !important;
}
</style>
